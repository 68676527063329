import { Overlay, PositionStrategy } from '@angular/cdk/overlay';
import {
  ComponentPortal,
  ComponentType,
  TemplatePortal,
} from '@angular/cdk/portal';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';

export interface OverlayOpenRef {
  dismissOverlay: (data?: any) => void;
  overlayDismissed: Subject<any>;
}
@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  constructor(private readonly overlay: Overlay) {}

  openOverlay<T, U>({
    component,
    templateRef,
    positionStrategy,
    viewContainerRef,
  }: {
    component?: ComponentType<T>;
    templateRef?: TemplateRef<U>;
    positionStrategy: PositionStrategy;
    viewContainerRef?: ViewContainerRef;
  }): OverlayOpenRef {
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy,
      disposeOnNavigation: true,
      backdropClass: '',
    });

    let portal: ComponentPortal<T> | TemplatePortal<U>;
    if (component) {
      portal = new ComponentPortal<T>(component);
    } else {
      portal = new TemplatePortal<U>(templateRef!, viewContainerRef!);
    }

    const overlayDismissed = new Subject<boolean>();
    const dismissOverlay = (data: any) => {
      overlayRef?.dispose();
      overlayDismissed.next(data);
    };

    overlayRef.backdropClick().subscribe((data: any) => {
      dismissOverlay(data);
    });

    const attachedOverlayRef = overlayRef.attach(portal);
    if (component) {
      attachedOverlayRef.instance?.dismissOverlay.subscribe((data: any) =>
        dismissOverlay(data)
      );
    }

    return {
      dismissOverlay,
      overlayDismissed,
    };
  }
}
