import { env } from '../../../../../environments/environment';

export const CONSTANTS = {
  APPLICATION_ID: 'ai-search',
  API: {
    SEARCH_DOCUMENT: env.API_URL + '/documents',
    UPLOAD_DOCUMENT: env.API_URL + '/documents',
    VIEW_DOCUMENT: env.API_URL + '/documents/content?filePath=<filePath>',
    GET_METADATA: env.API_URL + '/documents/metadata',
    UPDATE_METADATA: env.API_URL + '/documents/metadata',
    REPLACE_DOCUMENT: env.API_URL + '/documents/replace',
  },
  VALIDATION: {
    MAX_SEARCH_LENGTH: 1000,
    MIN_SEARCH_LENGTH: 0,
    MIN_CHARACTER_TO_TYPE_STARTING_SEARCH: 3
  },
  METADATA: {
    TYPE: {
      MAIN_TEXT_SEARCH: 'search-text',
      TEXT: 'text',
      CHOICE: 'choice',
      CALENDAR: 'calendar',
      BIGTEXT: 'bigtext',
      TAG: 'tag',
    },
    STATUS: {
      INDEXING: 'INDEXING',
      NO_CHANGES: 'NO_CHANGES',
      REMOVED: 'REMOVED',
    },
  },
  FORMAT: {
    DATE: 'MM/dd/yyyy',
  },
};
