import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import {
  askAssistant,
  clearChat,
  setChatWindowVisibility,
} from '../data/actions/chatbot.action';
import { AppState } from '../data/state/app.state.model';
import { IChatbotMessage } from '../data/state/chatbot.state.model';
import { AuthService } from './auth.service';
import { UserSubscriptionService } from './user-subscriptions.service';

@Injectable({
  providedIn: 'root',
})
export class GlboalAiChatService {
  CHATBOT_FEATURE_KEY = 'chatbot';

  messages = this.store$
    .select((state) => state.docugenAi.conversation.data.messages)
    .pipe(
      map((messages) =>
        messages.map((m) => ({
          ...m,
          content: this.domSanitizer.sanitize(
            SecurityContext.HTML,
            m.content
          ) as string,
        }))
      )
    );

  loading = this.store$.select(
    (state) => state.docugenAi.conversation.status === 'loading'
  );

  chatWindowCurrentVisibility = this.store$.select(
    (state) => state.docugenAi.windowOpened
  );

  chatIntroText = this.userSubscriptionService.activeSubscription$.pipe(
    map((sub) => {
      const features = sub?.appConfiguration?.features;

      if (features && Array.isArray(features)) {
        const chatBoxFeature = features.find(
          (feature) => feature.key === this.CHATBOT_FEATURE_KEY
        );
        return chatBoxFeature?.introText;
      }
    })
  );
  featureEnabled$ = this.userSubscriptionService.activeSubscription$.pipe(
    map(
      (activeSubscription) =>
        activeSubscription?.appConfiguration?.features?.find(
          (feature) => feature.key === this.CHATBOT_FEATURE_KEY
        )?.enabled === true
    )
  );

  constructor(
    private readonly store$: Store<AppState>,
    private readonly userSubscriptionService: UserSubscriptionService,
    private readonly domSanitizer: DomSanitizer,
    private readonly authService: AuthService
  ) {}

  clearChat() {
    this.store$.dispatch(clearChat());
  }

  sendUserMessage(userMessage: string) {
    if (!userMessage) {
      return;
    }
    const message: IChatbotMessage = { role: 'user', content: userMessage };
    this.store$.dispatch(askAssistant({ payload: message }));
  }

  setChatWindowVisibility(opened: boolean) {
    this.store$.dispatch(setChatWindowVisibility({ payload: opened }));
  }
}
