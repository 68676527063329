import { Injectable } from '@angular/core';
import { LoggerService } from '../../../../../../shared/services/logger.service';
import { HttpClient } from '@angular/common/http';
import { env } from 'src/environments/environment';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportTicketService {
  readonly #baseUrl: string;

  constructor(
    private readonly logger: LoggerService,
    private readonly httpClient: HttpClient
  ) {
    this.#baseUrl = `${env.API_URL}/support-ticket`;
    this.logger.log(
      'SupportTicketService.constructor: baseUrl = ',
      `${this.#baseUrl}`
    );
  }

  createTicket(
    type: string,
    topic: string,
    description: string,
    contact: string
  ) {
    this.logger.debug(
      'SupportTicketService.createTicket: ',
      `${this.#baseUrl}`
    );
    const url = `${this.#baseUrl}`;
    return this.httpClient
      .post(url, {
        workItemType: type,
        title: topic,
        description: description,
        contact: contact,
      })
      .pipe(first());
  }
}
