import { AsyncPipe, CommonModule, NgClass, NgStyle } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { finalize, tap } from 'rxjs';
import { GlboalAiChatService } from '../../../shared/services/chatbot.service';
import { TypingEffectComponent } from '../typing-effect/typing-effect.component';
import { UserQueryInputComponent } from '../user-query-input/user-query-input.component';
import { VerticalActionMenuComponent } from '../vertical-action-menu/vertical-action-menu.component';
import { FormatTextPipe } from '../../../shared/pipes/format-text.pipe';
import { LoadingIndicatorService } from '../../loading-indicator';
import { DocumentApiService } from '../../../dashboard/integrations/custom/docu-gen-ai/shared/services/document-api.service';

@Component({
  selector: 'app-chat-window',
  imports: [
    NgStyle,
    AsyncPipe,
    NgClass,
    UserQueryInputComponent,
    TranslateModule,
    TypingEffectComponent,
    VerticalActionMenuComponent,
    FormatTextPipe,
  ],
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent {
  @ViewChild('messagesContainer') messagesContainer!: ElementRef;
  @Output() closed = new EventEmitter<boolean>();

  readonly maxUserInput = 250;
  messages$ = this.chatbotService.messages.pipe(
    tap((messages) => {
      if (messages.length > 0) this.scrollToBottom();
    })
  );
  chatIntroText$ = this.chatbotService.chatIntroText;

  readonly PAGE_SEPARATOR = '@page';

  isLoading = false;

  constructor(
    private readonly documentService: DocumentApiService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    public chatbotService: GlboalAiChatService
  ) {}

  async onUserInput(userMessage: string) {
    if (!userMessage) {
      return;
    }

    this.chatbotService.sendUserMessage(userMessage);
  }

  scrollToBottom() {
    setTimeout(() => {
      this.messagesContainer.nativeElement.scroll({
        top: this.messagesContainer.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 200);
  }

  clearChat() {
    this.chatbotService.clearChat();
  }

  formatResponse(message: any) {
    if (!message) {
      return '';
    }

    if (message.role === 'user') {
      return message.content;
    }

    const fileReferences = message.fileReferences;
    let content = message.content;
    if (fileReferences && fileReferences.length > 0) {
      fileReferences.forEach((ref: any) => {
        if (ref?.key) {
          const pageNumber = ref.pageNumber
            ? this.PAGE_SEPARATOR + ref.pageNumber
            : '';
          content = content.replaceAll(
            ref.key,
            ` <a class="chatbot-link" href="#" title="${ref.fileName}${pageNumber}">${ref.fileName}${pageNumber}</a>`
          );
        }
      });
    }
    return content;
  }

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent) {
    const target = event.target as HTMLAnchorElement;

    if (target.tagName === 'A' && target.className === 'chatbot-link') {
      event.preventDefault();

      const title = target.title;
      if (!title) {
        return;
      }

      const arr = title.split(this.PAGE_SEPARATOR);
      const filePath = arr[0];
      const pageNumber = arr[1] && arr[1].length > 0 ? arr[1] : undefined;
      this.onClickViewDocument(filePath, pageNumber);
    }
  }

  onClickViewDocument(documentUrl: string, pageNumber?: string): void {
    this.showLoadingIndicator(true);
    this.documentService
      .openResource(documentUrl)
      .pipe(
        finalize(() => {
          this.showLoadingIndicator(false);
        })
      )
      .subscribe(
        (data) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL =
            URL.createObjectURL(file) +
            (pageNumber ? '#page=' + pageNumber : '');
          const newTab = window.open('', '_blank');
          if (newTab) {
            newTab.location.href = fileURL;
            newTab.onload = () => {
              URL.revokeObjectURL(fileURL); // Clean up after the PDF is opened
            };
          } else {
            console.error('Failed to open new tab');
          }
        },
        (error) => {
          console.error('Failed to fetch PDF resource', error);
        }
      );
  }

  showLoadingIndicator(show: boolean) {
    this.loadingIndicatorService.changeConfig({
      show: show,
      message: '',
    });
    this.isLoading = show;
  }
}
