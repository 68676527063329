import { Component } from '@angular/core';


@Component({
    selector: 'app-gray-box',
    imports: [],
    templateUrl: './gray-box.component.html',
    styleUrls: ['./gray-box.component.scss']
})
export class GrayBoxComponent {

}
