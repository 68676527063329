import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, filter, firstValueFrom, map } from 'rxjs';
import { AppState } from '../../../../../../shared/data/state/app.state.model';
import {
  ActionTypes,
  View,
  ViewMenuItem,
} from '../../../../../../shared/models';
import { updateInterfaceTitle } from '../../../../../../shared/data/actions/interface.actions';
import { UserSubscriptionService } from '../../../../../../shared/services/user-subscriptions.service';

@Injectable({
  providedIn: 'root',
})
export class CustomRouteService {
  private _activatedRoute?: ActivatedRoute;
  constructor(
    private router: Router,
    private store$: Store<AppState>,
    private userSubscriptionService: UserSubscriptionService
  ) {}
  set activatedRoute(route: ActivatedRoute) {
    this._activatedRoute = route;
  }
  views$: Observable<View[]> = this.store$.select(
    (state) => state.subscription.views
  );

  redirectTo(id: string, data?: any) {
    this.views$
      .pipe(
        map((views) =>
          views
            ?.flatMap((view) => view.menuitems)
            .find((menuItem) => menuItem.id === id)
        ),
        filter((menuItem) => !!menuItem)
      )
      .subscribe((menuItem) => this.doRedirect(menuItem, data));
  }

  private async doRedirect(item?: ViewMenuItem, data?: any) {
    if (!item) {
      return;
    }
    if (item.action.type === ActionTypes.ExternalLink) {
      window.open(item.action.url, '_blank');
    } else {
      this.store$.dispatch(updateInterfaceTitle({ payload: item.label }));
      const routeURL = await this.getUrlWithActiveView(item.id);
      const navigationExtras: NavigationExtras = {
        relativeTo: this._activatedRoute,
        state: { data: data ? data : undefined },
      };
      this.router.navigate([routeURL], navigationExtras);
    }
  }

  private async getUrlWithActiveView(pageId: string) {
    const activeView = await firstValueFrom(
      this.userSubscriptionService.activeView$
    );

    return `${activeView.id}/${pageId}`;
  }
}
