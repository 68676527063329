import { Component } from '@angular/core';
import { GlboalAiChatService } from '../../../shared/services/chatbot.service';

@Component({
    selector: 'app-vertical-action-menu',
    imports: [],
    templateUrl: './vertical-action-menu.component.html',
    styleUrl: './vertical-action-menu.component.scss'
})
export class VerticalActionMenuComponent {
  constructor(private chatbotService: GlboalAiChatService) {}

  clearChat() {
    this.chatbotService.clearChat();
  }
}
