<app-container-dialog
  header="{{ 'supportTicket.title' | translate }}"
  theme="info"
  [confirmButtonDisabled]="form.invalid"
  [confirmButtonText]="'supportTicket.submitButton' | translate"
  (confirmed)="submit()"
  (cancelled)="close.emit()"
>
  <div
    class="m-dialog__body"
    id="frontend-kit-dialog-alert-dialog-info-description"
  >
    <form [formGroup]="form">
      <p>
        {{ 'supportTicket.createForm' | translate }}
      </p>
      <div class="m-form-field">
        <div class="a-text-field">
          <label for="1">{{ 'supportTicket.topic' | translate }}*</label>
          <input
            type="text"
            formControlName="topic"
            id="1"
          />
        </div>
      </div>
      <div class="m-form-field">
        <div class="a-text-area">
          <label for="1"
            >{{ 'supportTicket.issueDescription' | translate }}*</label
          >
          <textarea
            formControlName="issueDescription"
            id="1"
          ></textarea>
        </div>
      </div>
      <div class="m-form-field">
        <div class="a-text-field">
          <label for="1"
            >{{ 'supportTicket.customerContact' | translate }}*</label
          >
          <input
            type="text"
            formControlName="customerContact"
            id="1"
          />
        </div>
      </div>
    </form>
  </div>
</app-container-dialog>
