
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { partitionArray } from '../../shared/util';

interface Option {
  label: string;
  value: any;
  showTooltip?: boolean;
  tooltipTranslationKey?: string;
  disabled?: boolean;
}
@Component({
    selector: 'app-custom-select',
    imports: [
        MatSelectModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        TranslateModule,
        MatCheckboxModule,
        MatTooltipModule
    ],
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements AfterViewInit {
  @Input() label!: string;
  @Input() optionList!: Array<Option>;
  @Input() multiSelect?: boolean = false;
  @Input() selectAllOptionVisible?: boolean = false;
  @Input() backgroundColor?: string = 'var(--bosch-gray-90)';
  @Input() wrapperHeight?: string = '48px';
  @Input() control: FormControl = new FormControl();

  _disabled: boolean = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  toggleSelection(event: MatCheckboxChange) {
    try {
      const [disabledOptions, enabledOptions] = partitionArray(
        this.optionList,
        (item) => !!item.disabled
      );

      const enabledValues = enabledOptions.map(
        (enabledOption) => enabledOption.value
      );
      const disabledValues = disabledOptions.map(
        (disabledOption) => disabledOption.value
      );

      const currentValue = this.control.value || [];

      const untouchables = currentValue.filter((value: string) =>
        disabledValues.includes(value)
      );

      const updatedValue = event.checked
        ? [...enabledValues, ...untouchables]
        : [...untouchables];

      this.control.patchValue(updatedValue);
    } catch (error) {
      console.info(
        'Error - An error occurred during the toggle selection process'
      );
    }
  }

  optionListTrackByFn(index: number, item: Option) {
    return item.label;
  }

  ngAfterViewInit(): void {
    const elements = this.elementRef.nativeElement.querySelectorAll(
      'mat-form-field > div.mat-mdc-text-field-wrapper'
    );
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'background-color', this.backgroundColor);
      this.renderer.setStyle(element, 'height', this.wrapperHeight);
    });

    const subElements = this.elementRef.nativeElement.querySelectorAll(
      'div.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align'
    );
    subElements.forEach((subElement: HTMLElement) => {
      this.renderer.setStyle(subElement, 'display', 'none');
    });
  }
}
