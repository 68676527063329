import { Injectable } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GlboalAiChatService } from 'src/app/shared/services/chatbot.service';

@Injectable({
  providedIn: 'root',
})
export class DocugenAiChatbotService {
  CHATBOT_FEATURE_KEY = 'chatbot';
  PREMIUM_USER_KEY = 'premiumUser';
  isPremiumUser$ = this.authService.userRoles$.pipe(
    map((userRoles: string[]) =>
      (userRoles || []).includes(this.PREMIUM_USER_KEY)
    )
  );
  showDocugenChatWidget$ = combineLatest([
    this.globalChatService.featureEnabled$,
    this.isPremiumUser$,
  ]).pipe(
    map(([featureEnabled, isPremiumUser]) => featureEnabled && isPremiumUser)
  );

  constructor(
    private readonly globalChatService: GlboalAiChatService,
    private readonly authService: AuthService
  ) {}
}
